import {
  computed, reactive, ref, watch, getCurrentInstance,
} from '@vue/composition-api'
import store from '@/store'
import http from '@/global/http'
import { avatarText } from '@core/utils/filter'

const REF_PRE_DATE = 'table-row-date-'

export default function usePlanList() {
  const isCollapsedCard = ref({
    pkh: false,
    dpc: false,
  })
  const isPopupPlanModal = ref(false)
  const planModalMode = ref('create')
  const planModalData = ref({})
  const tableShowFullContent = ref([])
  const plans = ref([])
  const departments = ref([])
  const monthFilter = ref('')
  const yearFilter = ref('')
  const contractList = ref([])
  const memberListRating = ref([])
  const teamLeader = ref({})
  const departmentOptions = computed({
    get: () => {
      let list = departments.value.filter(d => d.code.toLowerCase()
        .startsWith('d'))
      list = list.sort((a, b) => (a.code < b.code ? -1 : 1))
      return list
    },
    set: () => {
    },
  })
  const currentPlan = ref({})
  const searchQuery = ref('')
  const departmentFilter = ref('')
  const nearTodayRef = ref('')

  const { proxy } = getCurrentInstance()

  // Table Handlers
  const tableColumns = [
    {
      label: 'Ngày',
      key: 'schedule_at',
      tdClass: 'max-w-100px position-relative',
    },
    {
      label: 'Đội',
      key: 'department_name',
      thClass: 'w-40px border-right-gray',
    },
    {
      label: 'Sáng',
      key: 'morning_content',
      thClass: 'max-w-180px min-w-150px border-right-gray',
      tdClass: 'max-w-180px min-w-150px',
    },
    {
      label: 'Chiều',
      key: 'afternoon_content',
      thClass: 'max-w-180px min-w-150px border-right-gray',
      tdClass: 'max-w-180px min-w-150px',
    },
    // {
    //   label: 'Người đi xác minh',
    //   key: 'verify_works',
    //   tdClass: 'max-w-250px',
    // },
    {
      label: 'Cập nhật thực hiện',
      key: 'result',
      tdClass: 'max-w-600px min-w-450',
    },
    {
      label: 'Đánh giá',
      key: 'review',
      tdClass: 'max-w-80px',
    },
  ]
  const totalActualAmount = ref(0)
  const totalPercentAmount = ref(0)
  const perPage = ref(5)
  const currentPage = ref(1)
  const totalContracts = ref(0)
  const perPageOptions = [10, 25, 50, 100]
  const resultTitle = ref('')

  const resolveUserRoleVariant = role => {
    if (role === 'user') return 'primary'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const getRefByDate = dateString => {
    const date = new Date(dateString)
    return REF_PRE_DATE + date.toLocaleDateString()
      .replaceAll('/', '-')
  }

  const sortPlans = () => {
    plans.value = plans.value.sort((a, b) => ((a.department_code || '').replace(/^\D+/g, '') > (b.department_code || '').replace(/^\D+/g, '') ? 1 : -1))
  }

  const getDateRange = () => {
    if (!yearFilter.value) {
      return {
        from: '',
        to: '',
      }
    }

    return {
      from: !monthFilter.value ? new Date(yearFilter.value, 0, 1)
        : new Date(yearFilter.value, parseFloat(monthFilter.value - 1), 1),
      to: !monthFilter.value ? new Date(yearFilter.value, 12, 0) : new Date(yearFilter.value, monthFilter.value, 0, 23, 59, 59),
    }
  }

  const scrollToRef = refName => {
    const element = proxy.$refs[refName]
    const container = proxy.$refs['table-max-height-scroll']

    if (!element) return

    if (container) {
      const rect = element.getBoundingClientRect()
      const offsetTop = container.$el.scrollTop + rect.top - 200 // Trừ hao 200px
      container.$el.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      })
    } else if (element && typeof element.scrollIntoView === 'function') {
      element.scrollIntoView({ behavior: 'smooth' })
    } else {
      console.error('Element not found or scrollIntoView is not a function')
    }
  }

  const handleCheckNearToday = () => {
    const toDay = new Date()
    let theDayBefore = ''
    let theDayAfter = ''
    for (let i = 0; i < plans.value.length; i += 1) {
      const itemDate = new Date(plans.value[i].schedule_at)
      if (itemDate > toDay) {
        theDayAfter = REF_PRE_DATE + itemDate.toLocaleDateString().replaceAll('/', '-')
        break
      }
      theDayBefore = REF_PRE_DATE + itemDate.toLocaleDateString()
        .replaceAll('/', '-')
    }
    nearTodayRef.value = theDayAfter || theDayBefore
    setTimeout(() => {
      scrollToRef(nearTodayRef.value)
    }, 1000)
  }

  const fetchPlans = filter => {
    const {
      from,
      to,
    } = getDateRange()
    const queryString = http.buildQuery(filter || {
      page: currentPage.value - 1,
      perpage: perPage.value,
      q: searchQuery.value,
      department_id: departmentFilter.value,
      from: http.getISODate(from),
      to: http.getISODate(to),
    }) || ''
    return http.api(store.dispatch('plan/fetchPlans', queryString), res => {
      plans.value = (res.data || [])

      handleCheckNearToday()
    })
  }

  const fetchContractList = filter => {
    const queryString = http.buildQuery(filter || {
      page: currentPage.value - 1,
      perpage: 999,
      department_id: departmentFilter.value,
      m_status: 'contract_signed',
    }) || ''
    return http.api(store.dispatch('contract/fetchContracts', queryString), res => {
      contractList.value = res.data.filter(item => item.contract_code) || []
    })
  }
  const handleRowClick = row => {
    console.log('handleRowClick')
    if (!row.id) return
    if (tableShowFullContent.value.includes(row.id)) {
      tableShowFullContent.value = tableShowFullContent.value.filter(item => item !== row.id)
    } else {
      tableShowFullContent.value.push(row.id)
    }
    // tableShowFullContent[row.id] = tableShowFullContent[row.id] ? !tableShowFullContent[row.id].value : ref(false)
  }
  const fetchDepartments = filter => {
    const queryString = http.buildQuery(filter || {
      page: 0,
      perpage: 999,
    }) || ''
    return http.api(store.dispatch('department/fetchDepartments', queryString), res => {
      departments.value = res.data || []
    })
  }

  const fetchPlan = id => http.api(store.dispatch('plan/fetchPlan', { id }), res => {
    planModalData.value = res.data
    isPopupPlanModal.value = true
  })

  const updatePlan = data => http.api(store.dispatch('plan/updatePlan', data), res => {
    isPopupPlanModal.value = false
    planModalData.value = data
    Object.assign(plans.value.find(u => u.id === res.data.id), res.data)
  })

  const updateResult = data => http.api(store.dispatch('plan/updateResult', data), res => {
    planModalData.value = data
    Object.assign(plans.value.find(u => u.id === res.data.id), res.data)
  })

  const updateRating = data => http.api(store.dispatch('plan/updateRating', data), res => {
    isPopupPlanModal.value = false
    fetchPlans()
  })

  const updatePlanByAccountant = data => http.api(store.dispatch('plan/updatePlanByAccountant', data), res => {
    planModalData.value = res.data
    Object.assign(plans.value.find(u => u.id === res.data.id), res.data)
  })

  const createPlan = data => http.api(store.dispatch('plan/createPlan', data), res => {
    isPopupPlanModal.value = false
    plans.value.push({
      ...res.data,
      department_id: res.data.department_id.toString(),
      // eslint-disable-next-line no-nested-ternary
      _rowVariant: (parseFloat(res.data.actual_amount || 0) < parseFloat(res.data.expected_amount || 0) ? (new Date(res.data.schedule_at).getTime() < new Date().getTime() ? 'danger' : 'warning') : ''),
    })

    sortPlans()
  })

  const deletePlan = data => http.api(store.dispatch('plan/deletePlan', data), () => {
    const idx = plans.value.findIndex(u => u.id === data.id)
    plans.value.splice(idx, 1)
    isPopupPlanModal.value = false
  })

  watch([departmentFilter, monthFilter, yearFilter], () => {
    fetchPlans()
  })

  return {
    totalActualAmount,
    totalPercentAmount,
    tableColumns,
    perPage,
    currentPage,
    totalContracts,
    perPageOptions,
    resultTitle,
    isCollapsedCard,
    plans,
    REF_PRE_DATE,
    searchQuery,
    departmentOptions,
    contractList,
    tableShowFullContent,
    monthFilter,
    yearFilter,
    departmentFilter,
    departments,
    isPopupPlanModal,
    planModalMode,
    planModalData,
    currentPlan,
    memberListRating,
    teamLeader,
    nearTodayRef,
    fetchDepartments,
    handleRowClick,
    fetchContractList,
    fetchPlans,
    fetchPlan,
    updatePlan,
    updateResult,
    updateRating,
    updatePlanByAccountant,
    createPlan,
    deletePlan,
    avatarText,
    resolveUserRoleVariant,
    getRefByDate,
    handleCheckNearToday,
  }
}
