<template>
  <div>
    <b-sidebar
      id="add-new-plan-collection-sidebar"
      :visible="isShow"
      bg-variant="white"
      sidebar-class="sidebar-xl"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="(val) => $emit('update:isShow', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ modalMode === 'create' ? 'Tạo kế hoạch' : 'Chi tiết kế hoạch' }}
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />

        </div>

        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <form-required
              v-if="!autoSelectDepartmentId && !modalData.department_id"
              v-model="modalData.department_id"
              :select-options="departmentOptions"
              type="select"
              vid="department_id"
              label="Đội tiếp nhận"
              value-field="id"
              text-field="name"
              custom-v-error="Đội tiếp nhận là bắt buộc"
              rules="required"
              @update-value="val => modalData.department_id = val.toString()"
            />
            <div
              v-else
              class="position-relative"
            >
              <div>
                <label
                  class="field-required"
                >Đội tiếp nhận <span class="text-danger">*</span></label>
              </div>
              <p class="text-primary">
                {{
                  (departmentOptions.find(item => (item.id || '').toString() === (autoSelectDepartmentId || modalData.department_id || '').toString()) || {}).name
                }}
              </p>
            </div>

            <b-form-group
              label="Ngày thực hiện"
              label-for="ngay-thuc-hien-ke-hoach"
              class="field-required"
            >
              <flat-pickr
                id="ngay-thuc-hien-ke-hoach"
                v-model="modalData.schedule_at"
                class="form-control"
                :config="{ altFormat: 'd/m/Y', altInput: true, mode: 'single', allowInvalidPreload: true, dateFormat: 'Z', allowInput: false }"
              />
            </b-form-group>

            <b-form-group
              label="Kế hoạch sáng"
              label-for="ke-hoach-sang"
            >
              <b-form-textarea
                id="ke-hoach-sang"
                v-model="modalData.morning_content"
                placeholder="Sáng"
                rows="4"
              />
            </b-form-group>

            <b-form-group
              label="Kế hoạch chiều"
              label-for="ke-hoach-chieu"
            >
              <b-form-textarea
                id="ke-hoach-chieu"
                v-model="modalData.afternoon_content"
                placeholder="Chiều"
                rows="4"
              />
            </b-form-group>

            <b-form-group
              v-if="modalMode === 'edit'"
              label-for="cap-nhat-ket-qua"
            >
              <div class="d-flex justify-content-between">
                <label class="field-required">Kết quả</label>
                <!--                <feather-icon-->
                <!--                  v-b-tooltip.hover.bottom="'Cập nhật kết quả'"-->
                <!--                  icon="EditIcon"-->
                <!--                  size="20"-->
                <!--                  class="cursor-pointer text-success ml-2"-->
                <!--                  @click="$emit('update-result')"-->
                <!--                />-->
              </div>

              <pre class="p-1">{{ modalData.result || 'Chưa có kết quả' }}</pre>
            </b-form-group>

            <b-form-group
              v-if="modalMode === 'edit' && $ability().can('create', 'PlanRating')"
              label-for="danh-gia-ket-qua"
            >
              <div class="d-flex justify-content-between">
                <label class="field-required">Đánh giá</label>
                <feather-icon
                  v-if="!modalData.update_result_at || $ability().can('edit', 'PlanRating')"
                  v-b-tooltip.hover.bottom="'Rating'"
                  icon="EditIcon"
                  size="20"
                  class="cursor-pointer text-success ml-2"
                  @click="$emit('update-rating')"
                />
              </div>

              <div class="d-flex gap-25 flex-column">
                <div
                  v-for="(mem, memIdx) in modalData.work_plan_users"
                  :key="memIdx"
                  class="d-flex flex-column mb-50"
                >
                  <div class="d-flex justify-content-between">
                    <div>{{ mem.user && mem.user.fullname }}</div>
                    <b-form-rating
                      v-model="mem.rating_point"
                      no-border
                      stars="10"
                      readonly
                      variant="warning"
                      inline
                      size="sm"
                      style="height: 20px"
                    />
                  </div>
                  <small class="text-secondary">{{ mem.note }}</small>
                </div>
              </div>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2 justify-content-between flex-wrap">
              <div class="d-flex justify-content-end align-items-start flex-grow-1 ml-2 flex-wrap gap-1">
                <b-button
                  v-if="modalMode === 'edit' && ($ability().can('delete', 'Plans') || $ability().can('delete', 'TeamPlan'))"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="danger"
                  @click="$emit('delete')"
                >
                  Xóa
                </b-button>

                <b-button
                  v-if="modalMode === 'edit' && ($ability().can('edit', 'Plans') || $ability().can('edit', 'TeamPlan'))"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="ml-2"
                  type="submit"
                >
                  Lưu
                </b-button>

                <b-button
                  v-if="modalMode === 'create' && ($ability().can('create', 'Plans') || $ability().can('create', 'TeamPlan'))"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="ml-2"
                  type="submit"
                >
                  Tạo
                </b-button>

                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  class="ml-2"
                  variant="outline-secondary"
                  @click="hide"
                >
                  Hủy
                </b-button>
              </div>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
    <modal-change-password
      id="modal-admin-change-password"
      ref="modal-admin-change-password"
      modal-mode="admin-change-password"
      :user="modalData"
    />
  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormRating, BFormTextarea, BSidebar, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import { ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { alphaNum, email, required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import FormRequired from '@core/components/form-required/FormRequired.vue'
import ModalChangePassword from '@/views/settings/users/list/ModalChangePassword.vue'
import flatPickr from 'vue-flatpickr-component'
import http from '@/global/http'
import vSelect from 'vue-select'

export default {
  components: {
    BFormRating,
    BFormTextarea,
    BSidebar,
    BForm,
    BButton,
    FormRequired,
    ModalChangePassword,
    BFormGroup,
    Cleave,
    vSelect,

    // Form Validation
    ValidationObserver,
    flatPickr,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'isShow',
    event: 'update:isShow',
  },
  props: {
    modalMode: {
      type: String,
      default: null,
    },
    modalData: {
      type: Object,
      default: null,
    },
    autoSelectDepartmentId: {
      type: String,
      default: '',
    },
    isShow: {
      type: Boolean,
      required: true,
    },
    contractOptions: {
      type: Array,
      required: true,
    },
    departmentOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      refreshUploadFile: 1,
      uploadDropzoneOptions: {
        uploadMultiple: false,
        maxFiles: 1,
        init() {
          this.hiddenFileInput.removeAttribute('multiple')
        },
      },
    }
  },
  watch: {
    modalData() {
      this.refreshUploadFile += 1
    },
    // departmentOptions(n) {
    //   this.modalData.department_id = n.find(item => (item.id || '').toString() === (this.autoSelectDepartmentId || this.modalData.department_id || '').toString()).id.toString()
    // },
    autoSelectDepartmentId: {
      immediate: true,
      handler(n) {
        this.blankModalData.department_id = n.toString()
        this.modalData.department_id = n.toString()
      },
    },
  },
  methods: {
    openModalChangePassword() {
      this.$refs['modal-admin-change-password'].$refs['btn-open-modal-change-password'].click()
    },
    handleUploaded(files = []) {
      const avatar = files[0].thumbnail || ''
      Object.assign(this.modalData, { avatar })
    },
  },
  setup(props, { emit }) {
    const blankModalData = {
      contract_id: null,
      department_id: props.autoSelectDepartmentId.toString() || null,
      schedule_at: '',
      expected_amount: null,
      content: '',
    }

    const onSubmit = () => emit('submit', props.modalMode, {
      ...props.modalData,
      join_at: http.getISODate(props.modalData.join_at),
    })

    const planData = ref({
      ...blankModalData.value,
    })

    const resetUserData = () => {
      planData.value = JSON.parse(JSON.stringify(blankModalData))
    }

    const {
      refFormObserver,
      resetForm,
    } = formValidation(resetUserData)

    return {
      planData,
      onSubmit,
      refFormObserver,
      blankModalData,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-plan-collection-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
