import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPlans: (ctx, q) => axiosIns.get(`/api.work${q || ''}`),
    fetchPlan: (ctx, { id }) => axiosIns.get(`/api.work/${id}`),
    createPlan: (ctx, data) => axiosIns.post('/api.work', data),
    updatePlan: (ctx, data) => axiosIns.put(`/api.work/${data.id}`, data),
    updateResult: (ctx, data) => axiosIns.put(`/api.work/${data.id}/result`, data),
    updateRating: (ctx, data) => axiosIns.put(`/api.work/${data.id}/rating`, data),
    deletePlan: (ctx, { id }) => axiosIns.delete(`/api.work/${id}`),
  },
}
