<template>
  <div>

    <!-- Filters -->
    <b-card
      v-if="showFilter"
      no-body
      class="filter-sticky-top"
    >
      <b-card-body class="pb-1">
        <b-row>
          <b-col
            cols="12"
            sm="4"
            lg="3"
            class="mb-md-0 mb-50"
          >
            <label>Phòng ban</label>
            <v-select
              v-model="departmentFilter"
              :options="departmentOptions"
              class="w-100 select-size-sm"
              label="name"
              :reduce="val => val.id"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            lg="1"
            class="mb-md-0 mb-50 pl-md-0"
          >
            <label>Tháng</label>
            <v-select
              v-model="monthFilter"
              class="w-100 select-size-sm v-select-hiden-autocomplete"
              label="name"
              :options="$monthOptions"
              :clearable="true"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            lg="1"
            class="mb-md-0 mb-50 pl-md-0"
          >
            <label>Năm</label>
            <v-select
              v-model="yearFilter"
              class="w-100 select-size-sm v-select-hiden-autocomplete"
              label="name"
              :options="$yearOptions"
              :clearable="true"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card
      class="custom-border-top-1px"
    >
      <b-card-header class="pl-0 pt-0">
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          KẾ HOẠCH LÀM VIỆC
        </b-badge>
        <b-button
          v-if="($ability().can('create', 'Plans') || $ability().can('create', 'TeamPlan')) && isShowCreateNew"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="text-nowrap"
          @click="popupAddPlan"
        >
          <feather-icon
            class="text-white"
            icon="PlusIcon"
          />
          Thêm mới
        </b-button>
      </b-card-header>

      <b-table
        :ref="refName"
        class="position-relative text-center"
        :class="propClass"
        responsive
        primary-key="id"
        show-empty
        bordered
        small
        empty-text="Không có kết quả nào"
        :items="plans"
        :fields="tableColumns"
        @row-clicked="handleRowClick"
      >
        <template #thead-top>
          <b-tr>
            <b-th />
            <b-th class="border-right-gray" />
            <b-th
              colspan="2"
              class="text-primary border-right-gray"
            >
              PHÂN CÔNG TRIỂN KHAI
            </b-th>
            <b-th />
            <b-th />
          </b-tr>
        </template>

        <!--department_name-->
        <template #cell(department_name)="data">
          <div
            class="text-primary cursor-pointer"
            @click="goReportTeam(data.item)"
          >
            {{ data.item.department_name.replace(/\D/g, '') }}
          </div>
        </template>

        <!--schedule_at-->
        <template #cell(schedule_at)="data">
          <div
            v-if="date = new Date(data.item.schedule_at)"
            :ref="refDate = getRefByDate(date)"
            :class="nearTodayRef === refDate? 'scroll-to-row' : ''"
          >
            {{ date.getDate() < 10 ? `0${date.getDate()}` : date.getDate() }}/{{ ((date.getMonth() + 1) < 10) ? '0' + (date.getMonth() + 1) : date.getMonth() + 1 }}
            <br>
            {{ date.getFullYear() }}
          </div>
        </template>

        <!--Sáng-->
        <template #cell(morning_content)="data">
          <pre
            class="text-left bg-white border-0"
            v-html="data.item.morning_content"
          />
        </template>

        <!--Chiều-->
        <template #cell(afternoon_content)="data">
          <pre
            class="text-left bg-white border-0"
            v-html="data.item.afternoon_content"
          />
        </template>

        <!--Cập nhật thực hiện-->
        <template #cell(result)="data">
          <div>
            <div
              v-for="(comment, commentIdx) in data.item.work_comments"
              :key="data.item.id + commentIdx"
              class="font-small-3 text-left"
            >
              <span class="text-primary">
                <span
                  v-if="comment.contract"
                  class="text-success font-small-2 font-italic cursor-pointer text-monospace"
                  @click="gotoContractDetail(comment.contract)"
                >
                  {{ comment.contract.contract_code || comment.contract.document_code }}
                </span>
                <span class="font-weight-bolder">{{ comment.full_name }}:</span>
              </span>
              {{ comment.content }}
            </div>
          </div>
        </template>

        <!--Người đi xác minh-->
        <template #cell(verify_works)="data">
          <div>
            <div
              v-for="(verify, verifyIdx) in data.item.verify_works"
              :key="data.item.id + verifyIdx"
              class="d-flex justify-content-start font-small-3"
            >
              <span class="text-primary">{{ verify.user_confirm_name }}</span>
            </div>
          </div>
        </template>

        <!--Đánh giá-->
        <template #cell(review)="data">
          <div class="text-left">
            <template>
              <div
                v-for="(plan, planIdx) in data.item.work_plan_users"
                :key="data.item.id + planIdx"
                class="d-flex justify-content-between font-small-3"
              >
                <span
                  v-if="plan.user && plan.user.fullname"
                  class="text-primary text-nowrap"
                >{{ shortUsername(plan.user.fullname) }}:</span>
                <span
                  class="text-warning text-nowrap"
                >{{ plan.rating_point }}
                  <feather-icon
                    icon="StarIcon"
                    size="10"
                  />
                </span>
              </div>
            </template>

            <b-button
              size="sm"
              variant="primary"
              class="text-nowrap"
              @click="popupEditPlan(data.item)"
            >Chi tiết
            </b-button>
          </div>
        </template>

      </b-table>

      <div class="mb-3">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start mb-1 mb-md-0"
          >
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>mỗi trang</label>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalContracts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <sidebar-plan
      :is-show.sync="isPopupPlanModal"
      :department-options="departmentOptions"
      :contract-options="contractList"
      :auto-select-department-id="autoSelectDepartmentId"
      :modal-mode="planModalMode"
      :modal-data="planModalData"
      @submit="submitForm"
      @delete="openModalConfirmDeletePlan"
      @input-plan-result="inputDebtResult"
      @update-result="openModalUpdateResult"
      @update-rating="openModalupdateRating"
    />

    <b-modal
      ref="modal-confirm-delete-plan"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      centered
      header-text-variant="danger"
      title="Xác nhận xóa kế hoạch!"
      @ok="handleDeletePlan"
    >
      <b-card-text>
        Bạn thực sự muốn <span class="text-danger">xóa</span> kế hoạch
        <span
          v-if="currentPlan"
          class="text-primary font-weight-bolder"
        >{{ currentPlan.deparment_name }}</span> ngày
        <span
          class="text-primary font-weight-bolder"
        >{{ formatDate(currentPlan.schedule_at) }}</span>?
      </b-card-text>
    </b-modal>

    <b-modal
      ref="modal-update-result-plan"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      centered
      header-text-variant="success"
      ok-variant="success"
      title="Cập nhật kết quả"
      @ok="updateResult(currentPlan)"
    >
      <b-card-text>
        <b-form-group
          label="Kết quả"
          label-for="plan-result"
        >
          <b-form-textarea
            id="plan-result"
            v-model="currentPlan.result"
            rows="12"
            trim
          />
        </b-form-group>
      </b-card-text>
    </b-modal>

    <b-modal
      ref="modal-update-rating-plan"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      centered
      header-text-variant="primary"
      ok-variant="primary"
      title="Bảng đánh giá kế hoạch"
      @ok="handleUpdateRating"
    >
      <div
        v-if="teamLeader.fullname"
        class="mb-1 d-flex justify-content-between"
      >
        <span>Đội trưởng {{ currentPlan.department_name.toLowerCase() }}: <span class="text-primary"><strong>{{ teamLeader.fullname }}</strong> ({{ teamLeader.job_title_code }})</span></span>
        <span class="text-primary">{{ formatDate(currentPlan.schedule_at) }}</span>
      </div>

      <hr class="mb-2 mt-1">

      <b-card-text
        v-for="(member, memberIdx) in memberListRating"
        :key="memberIdx"
      >
        <b-form-group label-for="plan-review">
          <div class="d-flex justify-content-between">
            <label class="text-primary">{{ member.fullname }} <small>({{ member.job_title_code }})</small></label>

            <b-form-rating
              id="rating-10"
              v-model="member.rating_point"
              stars="10"
              inline
              no-border
              size="sm"
              variant="warning"
            />
          </div>

          <b-form-input
            v-model="member.note"
            rows="1"
            :placeholder="'Ghi chú cho ' + member.fullname"
            trim
          />
        </b-form-group>
      </b-card-text>
    </b-modal>

    <b-modal
      ref="modal-input-plan-result"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      centered
      header-text-variant="success"
      title="Cập nhật kế hoạch"
      @ok="editPlanByAccountant(planModalData)"
    >
      <b-card-text>
        Kế hoạch ngày <strong>{{ formatDate(planModalData.schedule_at) }}</strong> của <strong
          class=""
        >{{ planModalData.department_name }}</strong>
        <br>
        <br>
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group>
              <b-input-group>
                <cleave
                  id="amount_to_buy_original"
                  v-model="planModalData.actual_amount"
                  class="form-control text-primary"
                  :raw="true"
                  :options="formatOptions.number"
                  placeholder="Tiền kế hoạch"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group>
              <label
                class="mb-0"
                for="ket-qua-thu-no"
              >Kết quả thu</label>
              <b-input-group>
                <b-form-input
                  id="ket-qua-thu-no"
                  v-model="planModalData.result"
                  type="text"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BFormGroup,
  BFormInput,
  BCardHeader,
  BCardText,
  BCol,
  BMedia,
  BInputGroup,
  BRow,
  BTr, BTh,
  BPagination,
  BTable,
  BProgress,
  VBTooltip, BFormTextarea, BFormRating,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import userStoreModule from '@/views/settings/users/userStoreModule'
import { onUnmounted } from '@vue/composition-api'
import departmentStoreModule from '@/views/settings/departments/departmentStoreModule'
import Cleave from 'vue-cleave-component'
import contractStoreModule from '@/views/contracts/contractStoreModule'
import http from '@/global/http'
import planStoreModule from '../planStoreModule'
import usePlanList from './usePlanList'
import SidebarPlan from './SidebarPlanDetail.vue'

export default {
  components: {
    BFormRating,
    BFormTextarea,
    SidebarPlan,
    BCardHeader,
    BCardBody,
    BPagination,
    BCardText,
    BInputGroup,
    BCard,
    BTable,
    BProgress,
    BTr,
    BTh,
    BFormGroup,
    BFormInput,
    BBadge,
    BAvatar,
    Cleave,
    BMedia,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    isShowCreateNew: {
      type: Boolean,
      default: false,
    },
    refName: {
      type: String,
      default: '',
    },
    showFilter: {
      type: Boolean,
      default: true,
    },
    propClass: {
      type: String,
      default: '',
    },
    showBtnInputDebtResult: {
      type: Boolean,
      default: false,
    },
    autoSelectDepartmentId: {
      type: String,
      default: '',
    },
    propYearFilter: {
      type: String,
      default: '',
    },
    propMonthFilter: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      job_title_code: '',
    }
  },
  computed: {
    timeFilter() {
      if (!this.yearFilter) return 'TẤT CẢ'
      if (this.monthFilter) return `T${this.monthFilter}/${this.yearFilter}`
      return `NĂM ${this.yearFilter}`
    },
    canCreatePlan() {
      return this.job_title_code.toLowerCase() === 'tdpc'
    },
    canUpdateActualPlan() {
      return this.job_title_code.toLowerCase() === 'tdpc'
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.reportMode = this.$route.name
        const q = this.$route.query
        if (q.monthFilter !== undefined && q.yearFilter !== undefined) {
          this.monthFilter = q.monthFilter || ''
          this.yearFilter = q.yearFilter || ''
        } else {
          const currentDate = new Date()
          this.monthFilter = currentDate.getMonth() + 1
          this.yearFilter = currentDate.getFullYear()
        }
      },
    },
    currentPlan() {
      if (!this.currentPlan?.department_id) return
      this.$action(http.api(store.dispatch('department/fetchDepartment', { id: this.currentPlan.department_id }), res => {
        this.memberListRating = (res.data.users || []).map(item => {
          const rating = (this.currentPlan.work_plan_users || []).find(u => u.user_id === item.id) || {}
          return {
            ...item,
            ...rating,
          }
        }).filter(item => item.job_title_code.toLowerCase() !== 'tdpc') || []

        this.teamLeader = (res.data.users || []).find(item => item.job_title_code.toLowerCase() === 'tdpc') || {}
      }))
    },
    autoSelectDepartmentId: {
      immediate: true,
      handler(n) {
        this.departmentFilter = n
      },
    },
    propMonthFilter(n) {
      this.monthFilter = n || this.monthFilter || ''
    },
    propYearFilter(n) {
      this.yearFilter = n || this.yearFilter || ''
    },
    yearFilter() {
      this.updateRangeFilter()
    },
    monthFilter() {
      if (!this.yearFilter) return
      this.updateRangeFilter()
    },
  },
  created() {
    this.$action(this.fetchDepartments())
    this.$action(this.fetchPlans())
    // nếu đang ở menu kế hoạch kế hoạch, thì set filter tháng, năm, đội mặc định (đội 2)
    // if (this.$route.name === 'plan-collection') {
    //   this.departmentFilter = 5
    // }
  },
  methods: {
    handleDeletePlan() {
      this.deletePlan(this.currentPlan)
    },
    handleUpdateRating() {
      const data = {
        ...this.planModalData,
        work_plan_users: this.memberListRating.map(item => ({
          user_id: item.user_id || item.id,
          rating_point: item.rating_point,
          note: item.note || '',
        })).filter(item => item.rating_point),
      }
      this.updateRating(data)
    },
    getVariantByPercent(val) {
      if (val >= 100) return 'primary'
      if (val >= 50) return 'warning'
      return 'danger'
    },
    gotoContractDetail(c) {
      if (!c.id && !c.contract_id) return
      const id = c.contract_id || c.id
      this.$router.push({
        name: 'contracts-detail',
        params: { id },
      })
    },
    goReportTeam(d) {
      this.$router.push({
        name: 'reports-team',
        params: { id: d.department_id },
        query: {
          name: d.department_name,
          monthFilter: this.monthFilter,
          yearFilter: this.yearFilter,
          t: new Date().getTime(),
        },
      })
    },
    updateRangeFilter() {
      const m = this.monthFilter
      const y = this.yearFilter
      if (!y) {
        this.$emit('update:rangeDateFilter', ' to ')
      } else if (!m) {
        this.$emit('update:rangeDateFilter', `${y}-01-01 to ${y}-12-31`)
      } else {
        const endDate = new Date(y, m, 0).getDate()
        this.$emit('update:rangeDateFilter', `${y}-${m}-01 to ${y}-${m}-${endDate}`)
      }
    },
    openModalConfirmDeletePlan() {
      this.currentPlan = this.planModalData || {}
      this.$refs['modal-confirm-delete-plan'].show()
    },
    openModalUpdateResult() {
      this.currentPlan = this.planModalData || {}
      this.$refs['modal-update-result-plan'].show()
    },
    openModalupdateRating() {
      this.currentPlan = this.planModalData || {}
      this.$refs['modal-update-rating-plan'].show()
    },
    inputDebtResult() {
      this.currentPlan = this.planModalData || {}
      this.$refs['modal-input-plan-result'].show()
    },
    editPlanByAccountant() {
      const data = {
        ...this.planModalData,
        contract_id: parseFloat(this.planModalData.contract_id),
        department_id: parseFloat(this.planModalData.department_id),
        expected_amount: parseFloat(this.planModalData.expected_amount),
        actual_amount: parseFloat(this.planModalData.actual_amount),
      }
      this.$action(this.updatePlanByAccountant(data), true)
    },
    submitForm(modalMode, modalData) {
      const data = {
        ...modalData,
        contract_id: parseFloat(modalData.contract_id),
        department_id: parseFloat(this.autoSelectDepartmentId || modalData.department_id),
        expected_amount: parseFloat(modalData.expected_amount),
        actual_amount: parseFloat(modalData.actual_amount),
      }
      if (modalMode === 'create') {
        this.$action(this.createPlan(data), true)
      }
      if (modalMode === 'edit') {
        this.$action(this.updatePlan(data), true)
      }
    },
    popupAddPlan() {
      this.planModalMode = 'create'
      this.isPopupPlanModal = true
      this.planModalData = {}
    },
    popupEditPlan(row) {
      this.planModalMode = 'edit'
      // this.$action(this.fetchPlan(row.id))
      this.planModalData = {
        ...row,
        id: row.id.toString(),
      }
      this.isPopupPlanModal = true
    },
  },
  setup() {
    const PLAN_STORE_MODULE_NAME = 'plan'
    const USER_STORE_MODULE_NAME = 'user'
    const DEPARTMENT_STORE_MODULE_NAME = 'department'
    const CONTRACT_STORE_MODULE_NAME = 'contract'

    if (!store.hasModule(CONTRACT_STORE_MODULE_NAME)) store.registerModule(CONTRACT_STORE_MODULE_NAME, contractStoreModule)
    if (!store.hasModule(PLAN_STORE_MODULE_NAME)) store.registerModule(PLAN_STORE_MODULE_NAME, planStoreModule)
    if (!store.hasModule(USER_STORE_MODULE_NAME)) store.registerModule(USER_STORE_MODULE_NAME, userStoreModule)
    if (!store.hasModule(DEPARTMENT_STORE_MODULE_NAME)) store.registerModule(DEPARTMENT_STORE_MODULE_NAME, departmentStoreModule)
    onUnmounted(() => {
      if (store.hasModule(CONTRACT_STORE_MODULE_NAME)) store.unregisterModule(CONTRACT_STORE_MODULE_NAME)
      if (store.hasModule(PLAN_STORE_MODULE_NAME)) store.unregisterModule(PLAN_STORE_MODULE_NAME)
      if (store.hasModule(USER_STORE_MODULE_NAME)) store.unregisterModule(USER_STORE_MODULE_NAME)
      if (store.hasModule(DEPARTMENT_STORE_MODULE_NAME)) store.unregisterModule(DEPARTMENT_STORE_MODULE_NAME)
    })

    return {
      ...usePlanList(),
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.card-body .card-body {
  padding: .5rem;
}
</style>
