import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchContracts: (ctx, q) => axiosIns.get(`/api.contract${q || ''}`),
    fetchContract: (ctx, { id }) => axiosIns.get(`/api.contract/${id}`),
    revokeTeamContract: (ctx, { id }) => axiosIns.post(`/api.contract/${id}/revoke-team`, { id }),
    createContract: (ctx, data) => axiosIns.post('/api.contract', data),
    updateContract: (ctx, data) => axiosIns.put(`/api.contract/${data.id}`, data),
    updateHasPlan: (ctx, data) => axiosIns.put(`/api.contract/${data.id}/is-has-plan`, data),
    deleteContract: (ctx, { id }) => axiosIns.delete(`/api.contract/${id}`),
    sendDebSMS: (ctx, { id }) => axiosIns.put(`/api.debt-collection/send-sms/${id}`),

    fetchUsers: (ctx, q) => axiosIns.get(`/api.user${q || ''}`),
    getDebtCollection: (ctx, { id, type }) => axiosIns.get(`/api.debt-collection/${id}${type ? `?type=${type}` : ''}`),
    addDebtCollection: (ctx, data) => axiosIns.post('/api.debt-collection/add-debt-collection', data),
    editDebtCollection: (ctx, data) => axiosIns.put(`/api.debt-collection/${data.id}`, data),
    deleteDebtCollection: (ctx, { id }) => axiosIns.delete(`/api.debt-collection/${id}`),

    addConsulting: (ctx, data) => axiosIns.post('/api.debt-collection/add-consulting', data),
    editConsulting: (ctx, data) => axiosIns.put(`/api.debt-collection/${data.id}/consulting`, data),
    deleteConsulting: (ctx, { id }) => axiosIns.delete(`/api.debt-collection/${id}`),

    exportContracts: (ctx, q) => axiosIns.get(`/api.report/contract/export${q}`),
    assignTeam: (ctx, data) => axiosIns.post(`/api.contract/${data.id}/assign-team`, data),
    changeCreator: (ctx, { id, creator_id }) => axiosIns.post(`/api.contract/${id}/change-creator`, { creator_id }),
    verificationReport: (ctx, { id, verifyData }) => axiosIns.post(`/api.contract/${id}/verification-report`, verifyData),
    approve: (ctx, { id, status }) => axiosIns.post(`/api.contract/${id}/approve`, { status }),
    updateStatus: (ctx, data) => axiosIns.post(`/api.contract/${data.id}/update-status`, data),
    updateDocumentFiles: (ctx, { contractId, documentFiles }) => axiosIns.put(`/api.contract/${contractId}/document-files`, { document_files: documentFiles }),
    updateContractCode: (ctx, { contractId, appendix_code, contract_code }) => axiosIns.put(`/api.contract/${contractId}/contract-code`, { appendix_code, contract_code }),
    handleOver: (ctx, data) => axiosIns.put(`/api.contract/${data.id}/is-hand-over`, data),
    updateDocumentFilesName: (ctx, data) => axiosIns.put(`/api.contract/${data.contract_id}/document-file-names`, data),
    updateContractDate: (ctx, data) => axiosIns.put(`/api.contract/${data.id}/contract-date`, data),

    fetchActivities: (ctx, q) => axiosIns.get(`/api.activity${q || ''}`),
  },
}
